import {
  Box,
  MenuItem,
  MenuItemProps,
  SxProps,
  Typography,
  menuItemClasses,
  styled,
  svgIconClasses,
} from '@mui/material';
import cls from 'classnames';
import { isEmpty, isNil } from 'lodash';
import { ElementType, ReactElement, useContext, useId } from 'react';

import { MenuContext } from '../context';
import { SelectIcon } from './SelectIcon';

type BaseProps = {
  noWrap?: boolean;

  icon?: ReactElement;
  selected?: boolean;
  number?: string | number;

  closeMenuOnClick?: boolean;

  LinkComponent?: ElementType;
  to?: string;

  'aria-selected'?: boolean | 'true' | 'false';
};

const TYPOGRAPHY_MAX_WIDTH = '156px';
const TYPOGRAPHY_WITHOUT_PREFIX_MAX_WIDTH = '188px';
const TYPOGRAPHY_WITH_BOTH_PREFIXES_MAX_WIDTH = '124px';

type Props = BaseProps & Omit<MenuItemProps, keyof BaseProps>;

const classes = {
  selected: 'Item--selected',
  disabled: 'Item--disabled',
  noWrap: 'Item--noWrap',

  hasPrefix: 'Item--has-prefix',

  hasIcon: 'Item--has-icon',
  hasNumber: 'Item--has-number',

  number: 'Item__number',
};

const Item = styled(
  ({
    children,
    LinkComponent,
    to,
    selected,
    closeMenuOnClick = true,
    'aria-selected': ariaSelected,
    icon,
    noWrap = false,
    number,
    className,
    ...props
  }: Props) => {
    const id = useId();
    const menuContext = useContext(MenuContext);

    const isNotMenuComponent = isEmpty(menuContext);
    const { closeMenu } = menuContext ?? {};

    const isValidNumber = !isNil(number);

    const linkProps = LinkComponent
      ? {
          component: LinkComponent,
          to,
        }
      : {};

    const isSelectedViaAria = (() => {
      if (typeof ariaSelected === 'string') return ariaSelected === 'true';
      if (typeof ariaSelected === 'boolean') return ariaSelected;

      return false;
    })();

    const isItemSelected = selected || isSelectedViaAria;

    const onClick: typeof props.onClick = (e) => {
      props.onClick?.(e);
      closeMenuOnClick && closeMenu?.();
    };

    const getMaxWidth = () => {
      if (isNotMenuComponent) return undefined;
      if (icon && isValidNumber) return TYPOGRAPHY_WITH_BOTH_PREFIXES_MAX_WIDTH;
      if (!!icon || isValidNumber) return TYPOGRAPHY_WITHOUT_PREFIX_MAX_WIDTH;

      return TYPOGRAPHY_MAX_WIDTH;
    };

    return (
      <MenuItem
        {...props}
        {...linkProps}
        aria-selected={ariaSelected}
        onClick={onClick}
        className={cls(
          className,
          isItemSelected && classes.selected,
          !!icon && classes.hasIcon,
          isValidNumber && classes.hasNumber,
          props.disabled && classes.disabled,
          noWrap && classes.noWrap,
        )}
      >
        {isValidNumber && (
          <Box component="span" className={classes.number}>
            <Typography component="span" variant="caption">
              {number}
            </Typography>
          </Box>
        )}

        {icon}

        <Typography
          key={id + selected}
          maxWidth={getMaxWidth()}
          noWrap={noWrap}
          sx={{ overflowWrap: 'break-word' }}
          variant="body2"
        >
          {children}
        </Typography>

        {isItemSelected && (
          <Box sx={{ display: 'grid', placeContent: 'center', height: '20px' }}>
            <SelectIcon />
          </Box>
        )}
      </MenuItem>
    );
  },
)(({ theme: { spacing, palette } }) => {
  return {
    padding: spacing(2),
    color: palette.$text.base,
    minHeight: '36px !important',
    display: 'grid',

    gridTemplateColumns: '1fr',
    alignItems: 'start  !important',
    gap: spacing(2),

    [`&:not(&.${classes.noWrap})`]: {
      whiteSpace: 'normal',
    },

    [`&.${classes.hasIcon}, &.${classes.hasNumber}`]: {
      gridTemplateColumns: '24px 1fr',

      [`&.${classes.selected}`]: {
        gridTemplateColumns: '24px 1fr 24px',
      },
    },

    [`&.${classes.hasIcon}`]: {
      [`.${svgIconClasses.root}:first-of-type`]: {
        fontSize: '20px',
      },

      [`&.${classes.hasNumber}`]: {
        gridTemplateColumns: '24px 24px 1fr',

        [`&.${classes.selected}`]: {
          gridTemplateColumns: '24px 24px 1fr 24px',
        },
      },
    },

    [`&.${classes.selected}`]: {
      gridTemplateColumns: '1fr 24px',
    },

    [`&.${menuItemClasses.focusVisible}`]: {
      backgroundColor: palette.$white,
    },

    '&:hover': {
      backgroundColor: palette.$functional.bg_borders.hovered,
      color: palette.common.black,
    },

    '&:active': {
      backgroundColor: palette.$functional.bg_borders.pressed,
    },

    [`&.${classes.disabled}`]: {
      opacity: `1 !important`,
      color: palette.$text.disabled,

      path: {
        fill: palette.$text.disabled,
      },
    },

    [`.${classes.number}`]: {
      backgroundColor: palette.$grey.$100,
      display: 'grid',
      placeContent: 'center',
      borderRadius: '10px',
      paddingInline: spacing(2),
      marginTop: '2px',
      height: '16px',

      // Hack to center align text
      span: {
        height: '13px',
      },
    },
  } satisfies SxProps;
});

Item.displayName = 'Item';

export { Item, type Props as MenuItemProps };
export default Item;

import { AutocompleteRenderOptionState, SxProps } from '@mui/material';
import { isNil, merge } from 'lodash';
import { HTMLAttributes, Key, ReactNode } from 'react';

import { DrovaLight } from '@interface/mui-themes/lib/drova';

import { Menu } from '../Menu';

const escapeRegexp = (v: string) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export function highlightText(text: string, highlight: string): ReactNode {
  if (!highlight) return text;
  if (!text.toLocaleLowerCase().includes(highlight.toLocaleLowerCase())) return text;

  const elements = text
    .split(new RegExp(escapeRegexp(highlight.toLocaleLowerCase()), 'ig'))
    .reduce((arr, token, index, originalArr) => {
      // token: Oran; highlight = ge
      // OranGes => <Oran>Ges =>  Ge<s> => Ge
      let originalHighlightedCharacter = text.split(token)[1].slice(0, highlight.length);

      // Edge case when the matched character is the first character
      if (token === '' && index === 0) {
        originalHighlightedCharacter = text.slice(0, highlight.length);
      }

      return [
        ...arr,
        token,
        index !== originalArr.length - 1 && originalHighlightedCharacter && (
          <b key={`${token}${index}`}>{originalHighlightedCharacter}</b>
        ),
      ];
    }, [] as ReactNode[]);

  return elements;
}

export function defaultRenderOptions(params: {
  optionProps: HTMLAttributes<HTMLLIElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: any;
  state: AutocompleteRenderOptionState;

  getOptionLabel?: (v: string) => string;
  optionKey?: Exclude<Key, bigint> | string;
  drovaCcd?: boolean;
  freeSolo?: boolean;
  enableHighlightingOptionMatchedCharacters?: boolean;
  additionalKeysToTriggerFieldSubmit?: string[];
  theme: typeof DrovaLight;
}) {
  const {
    option,
    optionProps,
    state: { inputValue },
    optionKey,
    getOptionLabel,

    freeSolo = false,
    enableHighlightingOptionMatchedCharacters = false,
    additionalKeysToTriggerFieldSubmit = [],
    theme,
    drovaCcd,
  } = params;

  const { spacing } = theme;

  const extendedOptionProps = optionProps as typeof optionProps & { key: string };
  let key: Key = extendedOptionProps.key;

  if (optionKey) {
    key = typeof option === 'object' ? option?.[optionKey.toString()] : optionKey;
  }

  const optionValue = getOptionLabel?.(option) ?? (option as string);

  return (
    <Menu.Item
      {...merge(optionProps, {
        sx: {
          display: 'grid !important',
          padding: `${spacing(2, 4)} !important`,
        } satisfies SxProps,

        ...(additionalKeysToTriggerFieldSubmit.length !== 0 &&
          !freeSolo && {
            'data-value': JSON.stringify(option),
          }),
      })}
      data-drova-ccd={isNil(drovaCcd) || drovaCcd}
      key={key}
    >
      {enableHighlightingOptionMatchedCharacters
        ? highlightText(optionValue, inputValue || '')
        : optionValue}
    </Menu.Item>
  );
}

import {
  BackdropProps,
  PopoverProps as MuiPopoverProps,
  Paper,
  PaperProps,
  Popover,
  SxProps,
  useTheme,
} from '@mui/material';
import { merge } from 'lodash';
import { bindTrigger } from 'material-ui-popup-state';
import { bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import { FC, PropsWithoutRef, ReactElement, ReactNode, useId, useMemo } from 'react';

import { UtilityProps } from '../../utils/prop';
import { SlotConfigProps, useSlot } from '../../utils/slot';
import { MenuContext } from './context';
import { Item } from './internal/Item';
import { Group } from './slot/Group';
import {
  MenuDirection,
  MenuPlacement,
  addDividers,
  getOriginFromPlacementAndDirection,
} from './utils';

type MuiPopoverKey = keyof Pick<MuiPopoverProps, 'disablePortal'>;

type StaticProps = {
  displayStatic: true;
  renderAnchor?: never;
  stopPropagation?: never;
} & Partial<Record<MuiPopoverKey, never>>;

type PopoverProps = {
  displayStatic?: false | undefined;
  stopPropagation?: boolean;
  renderAnchor: (props: ReturnType<typeof bindTrigger>) => ReactElement;
} & Pick<MuiPopoverProps, MuiPopoverKey>;

type BaseProps = {
  /** The placement of the component */
  placement?: MenuPlacement | `${MenuPlacement}`;

  /** The direction of the component */
  direction?: MenuDirection | `${MenuDirection}`;

  /** The `props` object of `Backdrop` of the component */
  BackdropProps?: PropsWithoutRef<Partial<BackdropProps>>;

  /**  Content of the component */
  children?: ReactNode;
} & SlotConfigProps<'groups'> &
  UtilityProps &
  (StaticProps | PopoverProps);

type Props = BaseProps & Omit<PaperProps, keyof BaseProps>;
export type MenuProps = Props;

/** This component inherits [MUI Paper's API](https://mui.com/material-ui/api/paper/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/docs/elements-menu-props--documentation)
 */
const Menu: FC<Props> & { Group: typeof Group; Item: typeof Item } = ({
  children,
  renderAnchor,
  displayStatic,
  placement = MenuPlacement.BOTTOM_RIGHT,
  direction = MenuDirection.BOTTOM_LEFT,
  BackdropProps,
  stopPropagation,
  disablePortal,
  slotConfig = {},
  ...props
}: Props) => {
  const id = useId();
  const {
    shape: { borderRadius },
    spacing,
    palette,
  } = useTheme();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: id,
  });

  const { groups } = useSlot(children, merge({ groups: Group }, slotConfig));
  const groupsWithDividers = addDividers(groups, id);

  const paperProps = merge(
    {
      sx: {
        border: 'none',
        boxShadow: `inset 0px 0px 0px 1px ${palette.$solar.$700} !important`,
        padding: spacing(3),
        borderRadius: `${borderRadius} !important`,
        maxWidth: '260px',
      } satisfies SxProps,
    } as PaperProps,
    props,
  );

  const context = useMemo(
    () => ({
      closeMenu: () => {
        popupState.setOpen(false);
      },
    }),
    [popupState.setOpen],
  );

  if (displayStatic) {
    return <Paper {...paperProps}>{groups}</Paper>;
  }

  const triggerState = {
    ...bindTrigger(popupState),
  };

  return (
    <MenuContext.Provider value={context}>
      {renderAnchor?.(triggerState)}

      <Popover
        {...bindPopover(popupState)}
        {...getOriginFromPlacementAndDirection({ direction, placement })}
        slotProps={{ backdrop: BackdropProps }}
        PaperProps={paperProps}
        disablePortal={disablePortal}
        onClick={(e) => {
          stopPropagation && e.stopPropagation();
        }}
      >
        {groupsWithDividers}
      </Popover>
    </MenuContext.Provider>
  );
};

Menu.Group = Group;
Menu.Item = Item;

export { Menu };

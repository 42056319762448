import { DeepPartial } from '@apollo/client/utilities';
import { CloseOutlined, KeyboardArrowDownOutlined } from '@mui/icons-material';
import { SxProps, styled, svgIconClasses } from '@mui/material';
import cls from 'classnames';
import { merge } from 'lodash';
import { MouseEventHandler, forwardRef } from 'react';

import { useSlot } from '../../utils/slot';
import { Menu, MenuProps } from '../Menu';
import { Group } from '../Menu/slot/Group';
import { BaseLozenge, BaseLozengeProps, End, baseLozengeClasses } from './BaseLozenge';

type BaseProps = {
  /** If `true`, disable the element */
  disabled?: boolean;

  /** Function runs when the element is clicked */
  onDelete?: MouseEventHandler<HTMLDivElement>;

  /** Props passed down to `Menu` element */
  MenuProps?: DeepPartial<MenuProps>;

  /** Disabling color */
  color?: undefined;

  /** Disabling slot overriding */
  slotConfig?: undefined;
} & Pick<MenuProps, 'direction' | 'placement'>;

const classes = {
  disabled: 'InputLozenge--disabled',
  deletable: 'InputLozenge--deletable',
};

type Props = BaseProps & Omit<BaseLozengeProps, keyof BaseProps>;

const InputLozenge = styled(
  forwardRef<HTMLDivElement, Props>(
    (
      {
        children,
        disabled,
        className,
        direction = 'BOTTOM_RIGHT',
        placement = 'BOTTOM_LEFT',
        MenuProps = {},
        onDelete,
        ...props
      },
      ref,
    ) => {
      const { groups, unslotted } = useSlot(children, { groups: Group });
      if (groups.length) {
        return (
          <Menu
            {...merge(
              {
                direction,
                placement,
                sx: { mt: 1 },
                renderAnchor: ({ onClick, ...state }) => (
                  <BaseLozenge
                    {...merge(
                      {
                        tabIndex: 0,
                        role: 'button',
                      } as typeof props,
                      props,
                      state,
                    )}
                    ref={ref}
                    className={cls(className, disabled && classes.disabled)}
                    onClick={disabled ? undefined : onClick}
                  >
                    {unslotted}
                    <End data-drova-ccd={false}>
                      <KeyboardArrowDownOutlined />
                    </End>
                  </BaseLozenge>
                ),
              } as MenuProps,
              MenuProps,
            )}
          >
            {groups}
          </Menu>
        );
      }

      return (
        <BaseLozenge
          {...merge(
            {
              tabIndex: 0,
              role: 'button',
              onClick: onDelete,
            } as typeof props,
            props,
          )}
          ref={ref}
          className={cls(className, disabled && classes.disabled, classes.deletable)}
        >
          {unslotted}

          <End data-drova-ccd={false}>
            <CloseOutlined />
          </End>
        </BaseLozenge>
      );
    },
  ),
)(({ theme: { palette } }) => {
  return {
    backgroundColor: palette.$earth.$50,
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    color: palette.$earth.$500,

    paddingRight: '2px',
    display: 'flex',
    alignItems: 'center',

    [`.${baseLozengeClasses.end}`]: {
      borderRadius: '10px',
      boxSizing: 'border-box',

      height: '16px',

      display: 'grid',
      placeContent: 'center',

      color: palette.$earth.$400,
      marginLeft: '10px',

      paddingInline: '2px',
      paddingBlock: '3px',

      [`.${svgIconClasses.root}`]: {
        fontSize: '12px',
      },
    },

    [`&.${classes.disabled}`]: {
      cursor: 'default !important',
      border: `1px solid ${palette.$earth.$100}`,
      color: palette.$text.disabled,
      backgroundColor: 'transparent',

      [`.${baseLozengeClasses.end} .${svgIconClasses.root}`]: {
        color: palette.$text.disabled,
      },
    },

    [`:not(&.${classes.disabled})`]: {
      '&:hover, &:active, &:focus, &:focus-visible': {
        backgroundColor: palette.$earth.$100,

        [`.${baseLozengeClasses.end}`]: {
          backgroundColor: palette.$solar.$50,
        },
      },

      [`&.${classes.deletable}`]: {
        '&:hover, &:focus, &:focus-visible': {
          backgroundColor: palette.$earth.$100,

          [`.${baseLozengeClasses.end}`]: {
            backgroundColor: palette.$solar.$50,
          },
        },

        '&:active': {
          backgroundColor: palette.$earth.$100,
        },
      },
    },
  } satisfies SxProps;
});

InputLozenge.displayName = 'InputLozenge';
export { InputLozenge };
